import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

const fAlertToast = (xIsSuccess, xMessage) => {
  let vToasterStyle = {
    position: 'top-right',
    autoClose: xIsSuccess === 'SUCCESS' ? 5000 : 8000, // Auto close after 5s for success, 8s for others
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  };

  switch (xIsSuccess) {
    case 'SUCCESS':
      return toast.success(xMessage, vToasterStyle);
    case 'FAILED':
      return toast.error(xMessage, vToasterStyle);
    case 'WARNING':
      return toast.warning(xMessage, vToasterStyle);
    case 'INFO':
      return toast.info(xMessage, vToasterStyle);
    default:
      return toast.info(xMessage, vToasterStyle);
  }
};

export { fAlertToast };

export const getZoneColor = (percentage) => {
  if (percentage < 50) return 'Green';
  if (percentage < 75) return 'Yellow';
  if (percentage < 90) return 'Orange';
  return 'Red';
};

export const exportToExcel = (data, fileName = 'data', sheetName = 'Sheet1') => {
  const workSheet = XLSX.utils.json_to_sheet(data);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

export const leadStageColumns = [
  { field: 'id', headerName: 'Id', flex: 1, hide: true },
  {
    field: 'L_Desc',
    headerName: 'Description',
    width: 400
  },
  {
    field: 'AssigneeName',
    headerName: 'Assignee',
    align: 'left',
    headerAlign: 'left',
    width: 150
  },
  {
    field: 'ProjectManagerName',
    headerName: 'PM',
    align: 'left',
    headerAlign: 'left',
    width: 150
  },
  {
    field: 'RelationshipManagerName',
    headerName: 'RM',
    align: 'left',
    headerAlign: 'left',
    width: 150
  },
  {
    field: 'Stage01_Lead',
    headerName: 'Lead',
    align: 'center',
    headerAlign: 'center',
    width: 60
  },
  {
    field: 'Stage02_Confirmation',
    headerName: 'Confirmation',
    align: 'center',
    headerAlign: 'center',
    width: 110
  },
  {
    field: 'Stage03_Payment1',
    headerName: 'Payment1',
    align: 'center',
    headerAlign: 'center',
    width: 90
  },
  {
    field: 'Stage04_Installation',
    headerName: 'Installation',
    align: 'center',
    headerAlign: 'center',
    width: 90
  },
  {
    field: 'Stage05_Configuration',
    headerName: 'Configuration',
    align: 'center',
    headerAlign: 'center',
    width: 110
  },
  {
    field: 'Stage06_DataMigration',
    headerName: 'DataMigration',
    align: 'center',
    headerAlign: 'center',
    width: 120
  },
  {
    field: 'Stage07_Training',
    headerName: 'Training',
    align: 'center',
    headerAlign: 'center',
    width: 80
  },
  {
    field: 'Stage08_PostTraining',
    headerName: 'PostTraining',
    align: 'center',
    headerAlign: 'center',
    width: 110
  },
  {
    field: 'Stage09_FinalDataMigration',
    headerName: 'FinalDataMigration',
    align: 'center',
    headerAlign: 'center',
    width: 150
  },
  {
    field: 'Stage10_Payment2',
    headerName: 'Payment2',
    align: 'center',
    headerAlign: 'center',
    width: 90
  },
  {
    field: 'Stage11_Live',
    headerName: 'Live',
    align: 'center',
    headerAlign: 'center',
    width: 90
  },
  {
    field: 'Stage12_PostSupport',
    headerName: 'PostSupport',
    align: 'center',
    headerAlign: 'center',
    width: 100
  },
  {
    field: 'Stage13_ProjectClose',
    headerName: 'ProjectClose',
    align: 'center',
    headerAlign: 'center',
    width: 110
  },

  {
    field: 'Stage14_Payment3',
    headerName: 'Payment3',
    align: 'center',
    headerAlign: 'center',
    width: 90
  }
];

export const amcColumns = [
  { field: 'id', headerName: 'Id', flex: 1, hide: true },
  {
    field: 'CustName',
    headerName: 'Customer',
    width: 300
  },
  { field: 'Grp1Name', headerName: 'Group', width: 200 },
  { field: 'NosPos', headerName: 'POS', align: 'center', headerAlign: 'center', width: 80 },
  { field: 'NextYr', headerName: 'Year', align: 'center', headerAlign: 'center', width: 80 },
  { field: 'AMCAmt', headerName: 'AMC Amt', align: 'right', headerAlign: 'right', width: 100 },
  { field: 'SalesMan', headerName: 'Sales Man', width: 150 },
  { field: 'Contact', headerName: 'Contact', width: 200 },
  { field: 'Mobile', headerName: 'Mobile', width: 140 },
  { field: 'NextMnth', headerName: 'Month', align: 'center', headerAlign: 'center', width: 80 },
  {
    field: 'INVDate',
    headerName: 'INV Date',
    width: 120,
    valueGetter: (params) =>
      params.row.INVDate ? new Date(params.row.INVDate).toLocaleDateString() : ''
  },
  { field: 'INVAmt', headerName: 'INV Amt', align: 'right', headerAlign: 'right', width: 100 },
  { field: 'Status', headerName: 'Status', width: 80 },
  { field: 'INVNo', headerName: 'INV No', align: 'center', headerAlign: 'center', width: 120 },
  { field: 'INVTyp', headerName: 'INV Type', width: 100 },
  { field: 'FinalAmt', headerName: 'Final Amt', align: 'right', headerAlign: 'right', width: 80 },
  {
    field: 'TransSrNo',
    headerName: 'TransSrNo',
    align: 'center',
    headerAlign: 'center',
    width: 80
  },
  { field: 'LocName', headerName: 'Location', width: 150 },
  { field: 'BrName', headerName: 'Branch', width: 150, hide: true },
  { field: 'Email', headerName: 'Email', width: 200 }
];

const fKeyTabInput = (event, name) => {
  if (event.key === 'Enter') {
    document?.getElementsByName(name)[0]?.focus();
  }
};
const fKeyTabEnterInput = (event, id) => {
  if (event.key === 'Enter') {
    document?.getElementById(id)?.focus();
  }
};

const focusOnEnterInLoop = (e, nextFieldId) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    const el = document.getElementById(nextFieldId);
    if (el) el.focus();
  }
};

const formatPrice = (num) =>
  Number(num).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export { fKeyTabInput, fKeyTabEnterInput, focusOnEnterInLoop, formatPrice };
