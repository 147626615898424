import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  priority: [],
  zone: [],
  gridData: [],
  sftwrModules: []
};

const myTaskSlice = createSlice({
  name: 'myTask',
  initialState,
  reducers: {
    setPriority: (state, action) => {
      state.priority = action.payload;
    },
    setZone: (state, action) => {
      state.zone = action.payload;
    },
    setMyTaskGridData: (state, action) => {
      state.gridData = action.payload;
    },
    setMyTaskGridDataUpdate: (state, action) => {
      const { T_ID, updatedData } = action.payload;
      state.gridData = state.gridData.map((task) =>
        task.T_ID === T_ID ? { ...task, ...updatedData } : task
      );
    },
    setSftwrModules: (state, action) => {
      state.sftwrModules = action.payload;
    },
    resetMyTaskState: () => initialState
  }
});

export const {
  setPriority,
  setZone,
  setMyTaskGridData,
  setMyTaskGridDataUpdate,
  setSftwrModules,
  resetMyTaskState
} = myTaskSlice.actions;
export default myTaskSlice.reducer;
