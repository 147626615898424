import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Drawer,
  IconButton,
  Chip,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Badge,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { fAlertToast, fKeyTabInput } from '../Utility/Utility';
import { useDispatch, useSelector } from 'react-redux';
import '../components/pages.css';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { getTaskActivityAPI, insertTaskActivityAPI } from '../Action';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { CmpButton } from '../commonComponents/Toobox';
import theme from '../theme.js';
import { setMyTaskGridData, setMyTaskGridDataUpdate } from '../Slice/myTaskSlice.js';
import { setMySupervisingTaskGridDataUpdate } from '../Slice/mySupervisingSlice.js';

const UpdateFollowUpDrawer = ({
  drawerOpen,
  handleDrawerClose,
  selectedTask,
  filteredGridData,
  setFilteredGridData,
  xMediaQuery,
  setIsDrawerDataChange,
  heading,
  leadSummary
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
const formRef = useRef(null);

  const [activityLog, setActivityLog] = useState('');
  const [activityDate, setActivityDate] = useState('');
  const [activityType, setActivityType] = useState('Follow-Up');

  const [filteredData, setFilteredData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [userFilter, setUserFilter] = useState([]);

  const [isLeadSuccess, setIsLeadSuccess] = useState('N');
  const [leadType, setLeadType] = useState('Lead');

  const leadTypes = ['Lead', 'Meeting', 'Quotation', 'Negotiation', 'Completed'];

  useEffect(() => {
    if (drawerOpen) {
      setActivityDate(dayjs().add(1, 'day'));
    }
  }, [drawerOpen]);

  const handleAddActivity = useCallback(async () => {
    const payload = {
      A_T_ID: selectedTask?.T_ID || selectedTask?.L_TaskID,
      A_T_Type: selectedTask?.Lead_ID === 0 ? activityType : leadType,
      A_T_Desc: activityLog,
      A_T_EnBy: user?.userCd,
      A_T_NextFollowup: dayjs(activityDate).format('MM/DD/YYYY'),
      Lead: selectedTask?.Lead_ID || 0,
      A_T_IsSuccess: selectedTask?.Lead_ID !== 0 ? isLeadSuccess : '0'
    };

    const response = await insertTaskActivityAPI(payload);
    if (response.status === 200) {
      fAlertToast('SUCCESS', 'Task Activity inserted successfully');
      fetchTaskActivities();

      const updatedTask = {
        ...selectedTask,
        Modified: 1,
        RecentStatus: activityLog,
        nextFollowUp: dayjs(activityDate).format('MM/DD/YYYY'),
        FollowUpCount: (selectedTask?.FollowUpCount || 0) + 1
      };
      if (heading === 'My Work' && activityType === 'Completed') {
        // 1. Remove the task from filteredGridData
        const updatedFilteredData = filteredGridData?.filter(
          (task) => task.T_ID !== updatedTask.T_ID
        );
        // 3. Dispatch the updated filtered data to setMyTaskGridData (removing from "My Work")
        dispatch(setMyTaskGridData([...updatedFilteredData]));

        // 2. Update the local state to remove the task in my work
        setFilteredGridData([...updatedFilteredData]);

        // 4. Dispatch the updated task to setGridData (adding to "My Supervising")
        const updatedTaskWithStatus = {
          ...updatedTask,
          T_Status: 'Verification'
        };

        dispatch(
          setMySupervisingTaskGridDataUpdate({
            T_ID: updatedTaskWithStatus.T_ID,
            updatedData: updatedTaskWithStatus
          })
        );
      } else if (activityType !== 'Completed') {
        // Update task normally in "My Work" & "My Supervising"
        const updatedFilteredData = filteredGridData.map((task) =>
          task.T_ID === updatedTask.T_ID ? updatedTask : task
        );
        setFilteredGridData([...updatedFilteredData]);

        dispatch(
          setMyTaskGridDataUpdate({
            T_ID: updatedTask.T_ID,
            updatedData: updatedTask
          })
        );
        dispatch(
          setMySupervisingTaskGridDataUpdate({
            T_ID: updatedTask.T_ID,
            updatedData: updatedTask
          })
        );
      } else if (heading === 'My supervising' && activityType === 'Follow-Up') {
        // Update task normally in "My Work" & "My Supervising"
        const updatedFilteredData = filteredGridData.map((task) =>
          task.T_ID === updatedTask.T_ID ? updatedTask : task
        );
        setFilteredGridData([...updatedFilteredData]);
        dispatch(
          setMySupervisingTaskGridDataUpdate({
            T_ID: updatedTask.T_ID,
            updatedData: updatedTask
          })
        );
        dispatch(
          setMyTaskGridDataUpdate({
            T_ID: updatedTask.T_ID,
            updatedData: updatedTask
          })
        );
      }

      setActivityLog('');
      setActivityDate(dayjs().add(1, 'day'));
      handleDrawerClose();
      leadSummary();
      setIsDrawerDataChange(true);
    } else {
      fAlertToast('FAILED', 'Task Activity not inserted');
    }
  });

  const fetchTaskActivities = useCallback(async () => {
    const payload = {
      A_T_ID: selectedTask?.T_ID || selectedTask?.L_TaskID
    };
    const response = await getTaskActivityAPI(payload);

    if (response?.status === 200) {
      setApiData(response.data?.data);
      setTypeFilter(response.data?.count?.typeCount);
      setUserFilter(response.data?.count?.userCount);
    }
  });

  const handleChipClick = useCallback((filterType, label) => {
    let updatedFilter;
    switch (filterType) {
      case 'typeCount':
        updatedFilter = typeFilter?.map((item) => ({
          ...item,
          isSelected: item.label === label ? !item.isSelected : item.isSelected
        }));
        setTypeFilter(updatedFilter);
        break;

      case 'userCount':
        updatedFilter = userFilter?.map((item) => ({
          ...item,
          isSelected: item.label === label ? !item.isSelected : item.isSelected
        }));
        setUserFilter(updatedFilter);
        break;

      default:
        break;
    }
  });

  useEffect(() => {
    const selectedTypeArray = typeFilter
      ?.filter((item) => item.isSelected)
      .map((item) => item.label);

    const filteredByType = apiData?.filter((task) => {
      return selectedTypeArray?.length === 0 || selectedTypeArray?.includes(task.A_T_Type);
    });

    const selectedUserArray = userFilter
      ?.filter((item) => item.isSelected)
      .map((item) => item.label);

    const filteredByUser = filteredByType?.filter((task) => {
      return selectedUserArray?.length === 0 || selectedUserArray?.includes(task.A_T_EnByName);
    });

    setFilteredData(filteredByUser);
  }, [typeFilter, userFilter, apiData]);

  useEffect(() => {
    if (drawerOpen && selectedTask) {
      fetchTaskActivities();
    }
  }, [drawerOpen, selectedTask]);

  // Close drawer and reset form
  const handleClose = () => {
    handleDrawerClose();
    setActivityLog('');
    setActivityType('Follow-Up');
    setActivityDate(dayjs().add(1, 'day'));
  };

  return (
    <Drawer className="drawer" anchor="right" open={drawerOpen} onClose={handleClose}>
      <div>
        <IconButton
          onClick={handleClose}
          sx={{ cursor: 'pointer', color: 'GrayText', padding: xMediaQuery ? '4px' : '8px' }}>
          <CloseIcon />
        </IconButton>
      </div>
      <Box sx={{ paddingX: xMediaQuery ? '0px' : 3 }} className="task-detail">
        <Typography variant="h6" color="primary" sx={{ fontSize: xMediaQuery ? '14px' : '20px' }}>
          {selectedTask?.T_Title || selectedTask?.L_Title}
        </Typography>
        <Typography variant="body1" className="card-descript">
          {selectedTask?.T_Desc || selectedTask?.L_Desc}
        </Typography>
        <TextField
          label="Update Activity"
          id="activity"
          variant="outlined"
          type='textarea'
          name='activity'
          fullWidth
          value={activityLog}
          autoComplete='off'
          onChange={(e) => setActivityLog(e.target.value)}
          onKeyUp={(e) => {
            fKeyTabInput(e, 'followupDate');
          }}
          sx={{ '&.MuiTextField-root': { marginY: xMediaQuery ? '10px' : '16px' } }}
          multiline
         // rows={2}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Next followUp Date"
             name="followupDate"
             type='date'
              value={activityDate}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => setActivityDate(date, 'followupDate')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  type="date"
                  name='followupDate'
                  className="duedate"
                  onKeyUp={(e) => {
                    fKeyTabInput(e, 'type');
                  }}
                  sx={{ '&.MuiTextField-root': { marginRight: 1 } }}
                />
              )}
            />
          </LocalizationProvider>
          {selectedTask?.Lead_ID === 0 && (
            <FormControl fullWidth sx={{ '&.MuiFormControl-root': { marginRight: 1 } }}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                type='select'
                value={activityType}
                onChange={(e) => setActivityType(e.target.value)}
                label="Type">
                <MenuItem value="Follow-Up">Follow-Up</MenuItem>
                {heading === 'My Work' ? <MenuItem value="Completed">Completed</MenuItem> : <></>}
              </Select>
            </FormControl>
          )}

          {selectedTask?.Lead_ID !== 0 && (
            <FormControl fullWidth sx={{ '&.MuiFormControl-root': { marginRight: 1 } }}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={leadType}
                onChange={(e) => setLeadType(e.target.value)}
                label="Type">
                {leadTypes.map((typ) => (
                  <MenuItem key={typ} value={typ}>
                    {typ}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <CmpButton xbtnName="Save" xOnClick={handleAddActivity} />
        </div>

        {selectedTask?.Lead_ID !== 0 && leadType === 'Completed' && (
          <div style={{ textAlign: 'right' }}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="lead-success-group-label"
                name="lead-success-group"
                value={isLeadSuccess === 'Y' ? 'Successfull' : 'Un-Successfull'}
                onChange={(e) => setIsLeadSuccess(e.target.value === 'Successfull' ? 'Y' : 'N')}>
                <FormControlLabel
                  value="Successfull"
                  control={<Radio size="small" />}
                  label="Successfull"
                  sx={{ fontSize: '8px', marginRight: '24px !important' }}
                />
                <FormControlLabel
                  value="Un-Successfull"
                  control={<Radio size="small" />}
                  label="Un-Successfull"
                  sx={{ fontSize: '8px', marginRight: '0px !important' }}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        {/* Chips for Activity Types */}
        <Box sx={{ marginRight: 4, marginY: leadType === 'Completed' ? 0 : 1 }}>
          <Typography variant="body1" fontSize="14px">
            Types
          </Typography>
          <Grid container spacing={1} className="badgeClass">
            {typeFilter?.map((type) => (
              <Grid item key={type.label}>
                {type.label === 'Follow-Up' || type.label === 'Reminder' ? (
                  <Badge
                    badgeContent={type.count}
                    color={type.isSelected ? 'secondary' : 'primary'}
                    overlap="circular">
                    <Chip
                      className={type.isSelected ? 'filterSupChip' : ''}
                      label={
                        <div className="chipalign">
                          {type.isSelected && (
                            <IconButton size="small">
                              <FilterAltOutlinedIcon fontSize="small" />
                            </IconButton>
                          )}
                          {type.label}
                        </div>
                      }
                      color={type.isSelected ? 'secondary' : 'primary'}
                      onClick={() => handleChipClick('typeCount', type.label)}
                    />
                  </Badge>
                ) : (
                  <Chip
                    className={type.isSelected ? 'filterSupChip' : ''}
                    label={
                      <div className="chipalign">
                        {type.isSelected && (
                          <IconButton size="small">
                            <FilterAltOutlinedIcon fontSize="small" />
                          </IconButton>
                        )}

                        {type.label}
                      </div>
                    }
                    color={type.isSelected ? 'secondary' : 'primary'}
                    onClick={() => handleChipClick('typeCount', type.label)}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Chips for Usernames */}
        <Box sx={{ marginTop: 2, marginY: 1 }}>
          <Typography variant="body1" fontSize="14px">
            Users
          </Typography>
          <Grid container spacing={1} className="badgeClass">
            {userFilter?.map((user) => (
              <Grid
                item
                key={user.label}
                sx={{ '&.MuiGrid-item': { paddingTop: '4px !important' } }}>
                <Badge badgeContent={user.count} overlap="circular">
                  <Chip
                    className={user.isSelected ? 'filterSupChip' : ''}
                    label={
                      <div className="chipalign">
                        {user.isSelected && (
                          <IconButton size="small">
                            <FilterAltOutlinedIcon fontSize="small" />
                          </IconButton>
                        )}

                        {user.label}
                      </div>
                    }
                    color={user.isSelected ? 'secondary' : 'primary'}
                    onClick={() => handleChipClick('userCount', user.label)}
                  />
                </Badge>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Activity List */}
        {filteredData?.map((activity, index) => (
          <Card
            className="card"
            key={index}
            sx={{
              mb: 1,
              backgroundColor: 'background.drawer',
              border: `${theme.palette.shadow.borderClr} !important`,
              borderRadius: '10px'
            }}>
            <CardContent>
              <Box>
                <Typography className="descript" variant="body2">
                  {activity.A_T_Desc}
                  {activity.A_T_NextFollowup && (
                    <>
                      , Next Follow-up @{' '}
                      <span style={{ color: theme.palette.statusClr.red }}>
                        {moment(activity.A_T_NextFollowup).format('DD/MM/YYYY')}
                      </span>
                    </>
                  )}
                </Typography>

                <Typography
                  className="descriptDate"
                  variant="body2"
                  color={theme.palette.statusClr.high}>
                  <strong>{activity.A_T_EnByName}</strong>
                </Typography>
                <div className="aligntext">
                  <Typography className="descriptDate" variant="body2">
                    {moment(activity.A_T_Datetime).format('DD/MM/YYYY')}{' '}
                    {moment(activity.A_T_Datetime).format('hh:mm A')}
                  </Typography>
                  <Typography className="type" color={theme.palette.statusClr.low}>
                    {activity.A_T_Type}
                  </Typography>
                </div>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Drawer>
  );
};

export default UpdateFollowUpDrawer;
