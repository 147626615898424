import { httpRequest } from "./Server/Axios";

export const LoginTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/bakeryPreBooking/login`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const insertTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/insertTask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const getPendingQualifiedTasksAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/pendingQualifiedTasks`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const qualifyTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/qualifyTask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const deleteTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/deleteTask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const searchTaskCustomerAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/searchTaskCustomer`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const pendingTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/mypendingtask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  
  export const acceptTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/acceptmytask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const insertTaskActivityAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/insertTaskActivity`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const getTaskActivityAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/TaskActivity`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const supervisingTasksAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/SupervisingTasks`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const pendingTaskSummaryAssigneeAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/pendingSummaryAssignee`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
    
  export const pendingTaskSummarySupervisorAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/PendingTaskSummarySupervisor`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskStatisticsAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/taskStatistics`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskReminderAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/TaskReminder`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  
  export const todayActivityAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/today-activity`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const todayNotificationAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/task-notification`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const updateTaskReadStatusAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/update-task-read-status`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const salesLeadSummaryAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/SalesLeadSummary`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const updateSalesLeadAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/updateSalesLead`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const attributesAPI = async (apiObj) => {
    let apiMethod = {
      method: "GET",
      url: `/task/fAttributes`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const insertSalesLeadMeetingAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/insertSalesLeadMeeting`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const salesLeadMeetingAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/salesleadmeeting`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskLeadQuotationAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/taskLeadQuotation`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskLeadQuotationUpdateAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/taskLeadQuotationUpdate`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskActivityQualifyAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/taskActivityQualify`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  
  export const taskActivityValidationAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/taskActivityValidation`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const getClosedTasksByDateRangeAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/getClosedTasksByDateRange`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const activityHeatMapAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/activityHeatMap`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskClientVisitSummaryAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/taskClientVisitSummary`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const createTaskClientVisitAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/createUpdateTaskClientVisit`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const updateTaskClientVisitCompleteAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/updateTaskClientVisit`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const amcSummaryDataAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/amc/amcSummaryData`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const saveEnquiryAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/amc/saveEnquiry`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const generateInvoiceAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/amc/generateInvoice`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const sendEmailAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/amc/send-email`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskCompanyInsertAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/TaskCompanyInsert`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskLeadCompanyAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/TaskLeadCompany`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  export const updateSalesLeadConfirmationAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/updateSalesLeadConfirmation`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  export const taskSalesLeadStatusAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/TaskSalesLeadStatus`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  export const salesLeadConfirmationPDFAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/SalesLeadConfirmationPDF`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  export const getSalesLeadConfirmationPDFAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/getSalesLeadConfirmationPDF`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  export const updateSalesLeadPaymentAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/updateSalesLeadPaymentDocPDF`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  export const getSalesLeadPaymentDocPDFAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/getSalesLeadPaymentDocPDF`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  export const getTaskLeadInstallationAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/getTaskLeadInstallation`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
  export const updateTaskLeadInstallationAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/updateTaskLeadInstallation`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const updateTaskSalesLeadStageAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/updateTaskSalesLeadStage`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const getModuleQuickLaunchDataAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/getModuleQuickLaunchData`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };