import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import theme from '../theme.js';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';

export const CmpButton = ({
  xbtnName = '',
  xOnClick,
  xVariant = 'contained',
  xDisabled = false,
  xSize = 'small',
  xId = '',
  xSendIcon = '',
  xendIcon = ''
}) => {
  const fTextFieldDummy = (e) => {};
  return (
    <Button
      id={xId}
      onClick={xOnClick ? xOnClick : fTextFieldDummy}
      variant={xVariant}
      disabled={xDisabled}
      size={xSize}
      className="btn-text-transform-none btn-min-width btn-nav-font"
      sx={{
        width: 'auto',
        borderRadius: '20px',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.text,
        textTransform: 'capitalize'
      }}
      startIcon={xSendIcon}
      endIcon={xendIcon}>
      {xbtnName}
    </Button>
  );
};

export const CmpButtonError = ({
  xbtnName = '',
  xOnClick,
  xVariant = 'contained',
  xDisabled = false,
  xSize = 'small',
  xId = '',
  xSendIcon = '',
  xendIcon = ''
}) => {
  const fTextFieldDummy = (e) => {};
  return (
    <Button
      id={xId}
      onClick={xOnClick ? xOnClick : fTextFieldDummy}
      variant={xVariant}
      disabled={xDisabled}
      size={xSize}
      className="btn-text-transform-none btn-min-width btn-nav-font"
      sx={{
        width: 'auto',
        borderRadius: '20px',
        justifyContent: 'center',
        backgroundColor: theme.palette.statusClr.red,
        color: theme.palette.primary.text,
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: theme.palette.statusClr.red,
          color: theme.palette.primary.text
        }
      }}
      startIcon={xSendIcon}
      endIcon={xendIcon}>
      {xbtnName}
    </Button>
  );
};

export const CmpStyleButton = ({
  xbtnName = '',
  xOnClick,
  xVariant = 'outlined',
  xDisabled = false,
  xSize = 'small',
  xId = '',
  xSendIcon = '',
  xendIcon = ''
}) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  const fTextFieldDummy = (e) => {};
  return (
    <Button
      id={xId}
      onClick={xOnClick ? xOnClick : fTextFieldDummy}
      variant={xVariant}
      disabled={xDisabled}
      size={xSize}
      sx={{
        textTransform: 'capitalize',
        fontSize: vMediaQuery ? '12px' : '14px',
        width: { xs: '100%', sm: '90%', md: '90%' }
      }}
      startIcon={xSendIcon}
      endIcon={xendIcon}>
      {xbtnName}
    </Button>
  );
};

export const CmpTypographyField = ({ xcomponent, xText, xVariant = 'h6', xMediaQuery }) => {
  return (
    <div className="txt-overflow-ellipse">
      <Typography
        variant={xVariant}
        component={xcomponent}
        sx={{
          fontSize: xMediaQuery ? '14.6px' : '20px',
          textDecoration: 'underline',
          fontWeight: xMediaQuery ? 600 : ''
        }}>
        {xText}
      </Typography>
    </div>
  );
};

export const CmpTypography = ({ xcomponent, xText, xVariant = 'body1' }) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  return (
    <div className="txt-overflow-ellipse">
      <Typography
        variant={xVariant}
        component={xcomponent}
        sx={{
          fontSize: vMediaQuery ? '12px' : '14px',
          marginBottom: '4px',
          fontFamily: theme.palette.primary.font
        }}>
        {xText}
      </Typography>
    </div>
  );
};

export const CmpTypographyCom = ({ xcomponent, xText, className, xVariant = 'body1', style }) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  return (
    <div className="txt-overflow-ellipse">
      <Typography
        variant={xVariant}
        className={className}
        component={xcomponent}
        style={style}
        sx={{
          fontSize: vMediaQuery ? '12px' : '14px',
          marginTop: '4px',
          fontFamily: theme.palette.primary.font
        }}>
        {xText}
      </Typography>
    </div>
  );
};

export const CmpTypographyStandard = ({ xcomponent, xText, xVariant = 'body1' }) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  return (
    <div className="txt-overflow-ellipse">
      <Typography
        variant={xVariant}
        component={xcomponent}
        sx={{ fontSize: vMediaQuery ? '12px' : '14px', fontFamily: theme.palette.primary.font }}>
        {xText}
      </Typography>
    </div>
  );
};

export const SearchInput = ({
  xId,
  id,
  xOptions,
  xName,
  getOptionLabel,
  value,
  xType,
  xLabel,
  xOnChange,
  xOnInputChange,
  xOnBlur,
  xError,
  xHelperText,
  xOnKeyDown,
  xOnKeyUp
}) => {
  return (
    <div className="txt-overflow-ellipse">
      <Autocomplete
        freeSolo
         id={id}
        disableClearable
        type={xType}
        // name={xName}
        options={xOptions}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={xOnChange}
        onInputChange={xOnInputChange}
        onBlur={xOnBlur}
        onKeyDown={xOnKeyDown}
        onKeyUp={xOnKeyUp}
        renderInput={(params) => (
          <TextField
            {...params}
            id={xId}
            label={xLabel}
            name={xName}
            error={xError}
            helperText={xHelperText}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              type: 'search'
            }}
          />
        )}
      />
    </div>
  );
};

export const AnimatedSearchField = ({
  value,
  onChange,
  placeholder = 'Search...',
  onSearchClick = () => {},
  sx = {},
  ...props
}) => {
  const [sFocused, setSfocused] = useState(false);

  return (
    <TextField
      id="filter"
      variant="outlined"
      margin="normal"
      autoComplete="off"
      placeholder={sFocused ? placeholder : ''}
      value={value}
      onFocus={() => setSfocused(true)}
      onBlur={() => {
        if (!value) setSfocused(false);
      }}
      onChange={onChange}
      sx={{
        '& .MuiInputBase-input': {
          padding: '10px 14px !important'
        },
        '& .MuiFormLabel-root': {
          top: '-7px !important'
        },
        '& .MuiInputBase-root': {
          paddingRight: '0px !important',
          borderRadius: '20px !important',
          transition: 'all 0.4s ease',
          width: sFocused ? '100%' : '70px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: sFocused ? '1px solid rgba(0, 0, 0, 0.23)' : 'none'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: sFocused ? '1px solid rgba(0, 0, 0, 0.23)' : 'none'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: sFocused ? '1px solid #1976d2' : 'none'
        },
        ...sx
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setSfocused(true);
                onSearchClick();
              }}
              sx={{
                border: sFocused ? 'none' : '1px solid #dbd1d1',
                padding: '6px !important',
                backgroundColor: sFocused ? 'transparent' : 'white'
              }}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

export const NumberInputField = ({
  label,
  name,
  id,
  value,
  onChange,
  onEnterFocusId, // ID to focus on Enter
  fullWidth = true,
  onKeyUp,
  style,
  headline,
  error,
  helperText,
  ...props
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && onEnterFocusId) {
      e.preventDefault();
      document.getElementById(onEnterFocusId)?.focus();
    }
  };

  return (
    <TextField
      fullWidth={fullWidth}
      label={label}
      name={name}
      id={id}
      type="number"
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      onKeyUp={onKeyUp}
      error={error}
      helperText={helperText}
      style={style}
      sx={{
        '& .MuiOutlinedInput-input': {
          textAlign:
            headline === 'tblPriceGrp'
              ? 'center !important'
              : headline === 'tblAmount'
              ? 'right  !important'
              : 'left !important'
        }
      }}
      InputProps={{
        inputProps: {
          style: {
            MozAppearance: 'textfield'
          }
        },
        sx: {
          '& input[type=number]': {
            MozAppearance: 'textfield'
          },
          '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0
            }
        }
      }}
      {...props}
    />
  );
};

export const ReadOnlyDisplayField = ({
  value,
  label = '',
  variant = 'standard',
  fullWidth = true,
  ...props
}) => {
  return (
    <TextField
      fullWidth={fullWidth}
      variant={variant}
      value={value || ''}
      label={label}
      InputProps={{ readOnly: true }}
      {...props}
    />
  );
};

export const CustomCheckboxField = ({
  label,
  name,
  checked,
  onChange,
  onKeyUp,
  heading,
  onKeyDown,
  sx = {},
  ...props
}) => {
  return (
    <FormControlLabel
      className="checkbox-style"
      sx={{
        '.MuiCheckbox-root': {
          padding: heading === 'TaskDetail' ? '0px 9px !important' : ''
        },
        ...sx
      }}
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          {...props}
        />
      }
      label={label}
    />
  );
};
